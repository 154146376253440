import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { useDeviceType } from "../custom-hooks/useDeviceType";
import { DfpComponent } from "../ads/dfp-component";
import { HeroImage } from "../atoms/HeroImage";
import { PublishAndReadTime } from "../atoms/PublishAndReadTime";
import { HorizontalStory } from "./author-page/horizontal-stories/HorizontalStory";
import { Label } from "../atoms/Label";
import Headline from "../atoms/Headline";
import { getRecipeCookTime, getStoryHeadline, getStoryLabel, isExternalStory, storyTemplates } from "../utils/story";
import CookTime from "../atoms/CookTime";
import { getMenuGroups } from "../../../api/utils";

import "./tag.m.css";

const TagPage = (props) => {
  const [menuGroupsList, setMenuGroupsList] = useState([]);
  const deviceType = useDeviceType();
  const tagName = get(props, ["data", "tag", "name"], "");
  const isDesktop = deviceType === "desktop";
  const isMobile = deviceType === "mobile";
  const currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  const pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;

  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const stories = props.data.stories.map(({ story }) => story) || [];
  const label = getStoryLabel(stories[0]);
  const [visibleItems, setVisibleItems] = useState(6);
  const imageUrl =
    get(stories[0], ["hero-image-s3-key"], "") ||
    get(stories[0], ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");

  const coverImage = get(props, ["data", "collection", "metadata", "cover-image"], null);

  useEffect(async () => {
    setMenuGroupsList(await getMenuGroups());
  }, []);

  const sectionMenuList = menuGroupsList?.["menu-groups"]?.["main-nav"]?.items || [];

  const loadMoreStories = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };

  const firstStoryheadline = getStoryHeadline(stories[0]);
  const externalStoryUrl = isExternalStory(stories[0]);
  const href = externalStoryUrl || `/${stories[0]?.slug}`;
  const target = externalStoryUrl ? "_blank" : "_self";
  const isRecipe = stories[0] && stories[0]["story-template"] === storyTemplates.recipe;
  const cookTime = getRecipeCookTime(stories[0]);
  return (
    <div styleName="tag-page-container">
      <div className="container" styleName="wrapper">
        <div styleName="tag-container">
          {stories.length > 0 ? (
            <div styleName="firstStory">
              <Link href={href} target={target}>
                {label && (
                  <div styleName="label-container">
                    <Label story={stories[0]} />
                  </div>
                )}
                <div>{firstStoryheadline && <Headline headline={firstStoryheadline} />}</div>
              </Link>
              {stories[0]?.subheadline ? <h2>{stories[0].subheadline}</h2> : null}
              {!isRecipe ? (
                <PublishAndReadTime story={stories[0]} showReadTime={true} showTimestamp={true} />
              ) : (
                <CookTime cookTime={cookTime} />
              )}
              <Link href={href} target={target}>
                <HeroImage
                  story={stories[0]}
                  headline={stories[0].headline}
                  aspectRatio={[[4, 3]]}
                  iconSizes={{ height: 24, width: 24 }}
                  imageUrl={imageUrl}
                />
              </Link>
              <div styleName="row-separator"></div>
            </div>
          ) : (
            <div>
              <p styleName="no-article">We’re sorry, but no articles were found :(</p>
            </div>
          )}
          {stories.length > 0 && (
            <div styleName="articleList">
              {stories.slice(1, visibleItems).map((story) => (
                <HorizontalStory key={story.id} story={story} leftImage={true} />
              ))}
            </div>
          )}
          {visibleItems < stories.length && (
            <p styleName="loadmore-button" onClick={loadMoreStories}>
              LOAD MORE
            </p>
          )}
        </div>
        {isDesktop && (
          <div styleName="right-column">
            <div styleName="right-top-ad-slot">
              <DfpComponent
                adStyleName="ad-slot-size-300x600"
                id="tag-page-right-ad"
                path={rightTopAdUnit}
                size={adConfig1.sizes}
                viewPortSizeMapping={adConfig1.view_port_size_mapping}
              />
            </div>
          </div>
        )}
      </div>
      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName={"ad-slot-size-970x250"}
          id="tag-page-bottom-ad"
          path={BottomAdUnit}
          size={adConfig2.sizes}
          viewPortSizeMapping={adConfig2.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

export { TagPage };
TagPage.propTypes = {
  data: object,
};
